<template>
  <vue-country-code
    :default-country="defaultCountry"
    :class="isDark ? 'country-select-dark' : 'country-select'"
    :preferred-countries="preferredCountries"
    :enabled-country-code="enabledCountryCode"
    :enabled-flags="enabledFlags"
    @onSelect="onSelect"
  />
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  props: {
    enabledCountryCode: {
      type: Boolean,
      default: false,
    },
    enabledFlags: {
      type: Boolean,
      default: true,
    },
    defaultCountry: {
      type: String,
      default: 'TW',
    },
  },
  data() {
    return {
      preferredCountries: ['tw', 'us', 'jp', 'ca', 'cn', 'hk', 'my'],
    }
  },
  methods: {
    onSelect(item) {
      this.$emit('select-country', item)
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    return {
      isDark,
    }
  },
}
</script>

<style lang="scss">
</style>
