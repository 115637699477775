<template>
  <div>
    <b-modal
      :id="domainRuleId"
      no-close-on-backdrop
      ok-title="確認"
      centered
      ok-only
      @ok="handleOk"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          參數設定
        </h4>
      </template>

      <div>
        <b-row>
          <!-- 參數類別 / 是否必填 -->
          <b-col cols="12">
            <b-form-group
              label="參數類別"
              label-for="config-type"
            >
              <div class="d-flex align-items-center justify-content-between mb-50">
                <v-select
                  v-model="ruleData.type"
                  :options="ruleTypeOptions"
                  class="b-form-select w-100 mr-1"
                  :clearable="false"
                  :reduce="option => option.value"
                  @input="changeSelectType($event)"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>

                <toggle-button
                  v-model="ruleData.required"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="70"
                  :height="26"
                  :labels="{checked: '必填', unchecked: '非必填'}"
                  :sync="true"
                />
              </div>
            </b-form-group>
          </b-col>

          <!-- 參數名稱 -->
          <b-col
            cols="12"
            lg="7"
          >
            <b-form-group
              label="參數名稱"
              label-for="config-name"
            >
              <b-form-input
                id="config-name"
                v-model="ruleData.name"
                name="config-name"
                trim
              />
            </b-form-group>
          </b-col>

          <!-- 參數 -->
          <b-col
            cols="12"
            lg="5"
          >
            <b-form-group
              label="KEY"
              label-for="config-key"
            >
              <b-form-input
                id="config-name"
                v-model="ruleData.key"
                name="config-name"
                trim
                placeholder="key"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <hr>

        <b-row>
          <!-- 預設 -->
          <b-col
            v-if="isTypeInclude(true, ['TextType', 'NumericType'])"
            cols="12"
          >
            <b-form-group
              label="預設值"
              label-for="config-default"
            >
              <b-form-input
                id="config-default"
                v-model="ruleData.options.default"
                name="config-default"
                placeholder="請填入預設值"
              />
            </b-form-group>
          </b-col>

          <!-- 布林 -->
          <b-col
            v-if="isTypeInclude(true, ['BooleanType'])"
            cols="12"
          >
            <b-form-group
              label-cols="4"
              label="預設值"
              label-for="config-boolean"
            >
              <div class="demo-inline-spacing">
                <!-- <toggle-button
                  v-model="ruleData.options.default"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="70"
                  :height="26"
                  :labels="{checked: 'True', unchecked: 'False'}"
                  :sync="true"
                /> -->
                <b-form-radio
                  v-model="ruleData.options.default"
                  name="boolean-radios"
                  plain
                  :value="true"
                  class="mt-50"
                >
                  <span class="boolean-radios-text">True</span>
                </b-form-radio>
                <b-form-radio
                  v-model="ruleData.options.default"
                  name="boolean-radios"
                  plain
                  :value="false"
                  class="mt-50"
                >
                  <span class="boolean-radios-text">False</span>
                </b-form-radio>
              </div>

            </b-form-group>

          </b-col>

          <!-- 參數範圍（字） -->
          <b-col
            v-if="isTypeInclude(true, ['TextType'])"
            cols="12"
          >
            <b-form-group
              label="參數範圍（字數）"
              label-for="min"
            >
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="ruleData.options.min"
                  v-b-tooltip.hover.focus.v-secondary
                  name="config-min"
                  :title="'最小值'"
                  :type="'number'"
                  min="0"
                />
                <span class="px-50">
                  ~
                </span>
                <b-form-input
                  v-model="ruleData.options.max"
                  v-b-tooltip.hover.focus.v-secondary
                  name="config-max"
                  :title="'最大值'"
                  :type="'number'"
                  min="0"
                />
              </div>
            </b-form-group>
          </b-col>

          <!-- 數字範圍（值） -->
          <b-col
            v-if="isTypeInclude(true, ['NumericType'])"
            cols="12"
          >
            <b-form-group
              label="數字範圍（值）"
              label-for="min"
            >
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="ruleData.options.min"
                  v-b-tooltip.hover.focus.v-secondary
                  name="config-min"
                  :title="'最小值'"
                  :type="'number'"
                  min="0"
                />
                <span class="px-50">
                  ~
                </span>
                <b-form-input
                  v-model="ruleData.options.max"
                  v-b-tooltip.hover.focus.v-secondary
                  name="config-max"
                  :title="'最大值'"
                  :type="'number'"
                  min="0"
                />
              </div>
            </b-form-group>
          </b-col>

          <!-- 下拉選項 -->
          <b-col
            v-if="isTypeInclude(true, ['DropdownType'])"
            cols="12"
          >
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">下拉選項 ( 預設值 )</label>

                <span>
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="設定"
                    class="d-flex align-items-center actions-link-btn-sm"
                    @click="settingTableColData"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/settings.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </span>
              </div>

              <v-select
                v-model="ruleData.options.default"
                :options="ruleData.options.items"
                class="b-form-select"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>選項為空，請設定輸入或匯入選項</small>
                  </div>
                </template>
              </v-select>
            </div>
          </b-col>

          <!-- 多選下拉 -->
          <b-col
            v-if="isTypeInclude(true, ['DropdownMutiType'])"
            cols="12"
          >
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">多選下拉 ( 預設值 )</label>

                <span>
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="設定"
                    class="d-flex align-items-center actions-link-btn-sm"
                    @click="settingTableColData"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/settings.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </span>
              </div>

              <v-select
                v-model="ruleData.options.default"
                :options="ruleData.options.items"
                class="b-form-select mb-1"
                multiple
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>選項為空，請設定輸入或匯入選項</small>
                  </div>
                </template>
              </v-select>
            </div>
          </b-col>

          <!-- 字串串接符號 -->
          <b-col
            v-if="isTypeInclude(true, ['DropdownMutiType'])"
            cols="12"
          >
            <b-form-group
              label="串接符號"
              label-for="config-delimiter"
            >
              <b-form-input
                id="config-default"
                v-model="ruleData.options.delimiter"
                name="config-delimiter"
                placeholder="請填入串接符號"
              />
            </b-form-group>
          </b-col>

          <!-- 日期 -->
          <b-col
            v-if="isTypeInclude(true, ['DateType'])"
            cols="12"
          >
            <b-form-group
              :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
              label-for="format"
              class="m-0 mb-1"
            >
              <template #label>
                <h6 class="mb-0">
                  日期格式
                </h6>
              </template>

              <v-select
                v-model="ruleData.options.format"
                :options="timeFormatOptions"
                :clearable="false"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group
              v-if="ruleData.options.format === '自訂時間'"
              :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
              label-for="time_input"
              class="m-0 mb-1"
            >
              <template #label>
                <h6 class="mb-0">
                  自訂時間格式
                </h6>
              </template>

              <b-form-input
                id="time_input"
                v-model="ruleData.options.input"
                name="time_input"
                placeholder="Y-m-d H:i:s"
              />
            </b-form-group>

          </b-col>

          <!-- 國家 -->
          <b-col
            v-if="isTypeInclude(true, ['CountryType'])"
            cols="12"
          >
            <b-form-group
              label="國家"
              label-for="config-country"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <CountrySelect
                    :default-country="ruleData.options.iso2"
                    @select-country="(item) => {
                      ruleData.options.iso2 = item.iso2
                      ruleData.options.default = item.name
                    }"
                  />
                </b-input-group-prepend>

                <b-form-input
                  id="config-country"
                  v-model="ruleData.options.default"
                  placeholder="請填入預設值"
                />
              </b-input-group>
            </b-form-group>

          </b-col>

          <!-- 電話 -->
          <b-col
            v-if="isTypeInclude(true, ['PhoneType'])"
            cols="12"
          >
            <b-form-group
              label="電話"
              label-for="config-phone"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend
                  is-text
                  class="p-0 m-0"
                >
                  <CountrySelect
                    :default-country="ruleData.options.iso2"
                    @select-country="(item) => {
                      ruleData.options.dialCode = item.dialCode
                      ruleData.options.iso2 = item.iso2
                    }"
                  />
                  <span
                    v-if="ruleData.options.dialCode"
                    class="ml-1"
                  > +{{ ruleData.options.dialCode }} </span>
                </b-input-group-prepend>

                <b-form-input
                  id="config-phone"
                  v-model="ruleData.options.default"
                  type="number"
                  placeholder="請填入預設值"
                />
              </b-input-group>
            </b-form-group>

          </b-col>

          <!-- 正規 -->
          <!-- <b-col cols="12">
            <b-form-group
              label="正規表示式"
              label-for="rule"
            >
              <b-form-input
                id="config-rule"
                v-model="ruleData.rule"
                name="config-rule"
              />
            </b-form-group>
          </b-col> -->
        </b-row>
        <!-- {{ ruleData }} -->
      </div>
    </b-modal>

    <b-modal
      id="options-setting-modal"
      centered
      no-close-on-backdrop
      body-class="p-0"
      @hidden="handleHideSet"
    >
      <template #modal-title>
        <h5 class="m-0">
          選項設定
        </h5>
      </template>

      <div class="set-modal-content py-1 px-2">
        <optionsSetting
          ref="optionsSet"
          :data-options="ruleData.options.items"
        />
      </div>

      <template #modal-footer>
        <div class="w-100 text-right">
          <b-button
            variant="primary"
            size="sm"
            @click="handleOkSet"
          >
            確認
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
// API
import { ref } from '@vue/composition-api'

// UI
import {
  BRow, BCol, VBTooltip, BFormGroup, BFormInput, BButton, BImg, BInputGroup, BInputGroupPrepend, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ToggleButton } from 'vue-js-toggle-button'
import { useAlert } from '@/libs/mixins/index'
import { useDomainSetting } from '../../useDomain'
import optionsSetting from './OptionsSetting.vue'
import CountrySelect from '@/layouts/components/CountrySelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    ToggleButton,
    vSelect,
    optionsSetting,
    CountrySelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    domainRuleId: {
      type: String,
      default: 'domain-rule-modal',
    },
  },
  data() {
    return {
      missingFields: [],
    }
  },
  methods: {
    // (隱藏)類別
    isTypeInclude(type, hidden) {
      let state = !type
      hidden.forEach(item => {
        if (item === this.ruleData.type) state = type
      })
      return state
    },

    // (設定) 資料表
    settingTableColData() {
      this.$bvModal.show('options-setting-modal')
    },

    // 按下(設定)彈窗關閉
    handleHideSet(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$nextTick(() => {
        this.$bvModal.hide('options-setting-modal')
      })
    },

    // 按下(設定)彈窗確認(下拉式選單)
    handleOkSet(bvModalEvt) {
      bvModalEvt.preventDefault()
      let optionsData = []
      optionsData = this.$refs.optionsSet.getOptionsData()

      this.ruleData.options.items = optionsData.map((item, index) => {
        const data = {
          id: index,
          label: item.label,
          value: item.value,
        }
        return data
      })

      this.$nextTick(() => {
        this.$bvModal.hide('options-setting-modal')
        // this.resetOptions()
      })
    },

    // -----------------------------------------------------------------------------------
    // (切換)類型
    changeSelectType(type) {
      const resolveOptions = this.resolveRuleMode(type).options
      const resolveData = {
        ...this.syncObject(resolveOptions, this.ruleData.options),
      }
      this.ruleData.options = JSON.parse(JSON.stringify(resolveData))
      if (this.ruleData.options.default) this.ruleData.options.default = null
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      const requiredFields = {
        name: '參數名稱',
        key: 'KEY',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.ruleData[Object.keys(requiredFields)[i]] === null || this.ruleData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.$nextTick(() => {
        this.$emit('edit-rule', this.ruleData)
        this.$bvModal.hide(this.domainRuleId)
      })
    },

    // (取得)編輯資料
    getData(rule) {
      const resolveData = {
        ...this.syncObject(this.blankRuleData, rule),
      }
      const blankOptions = this.resolveRuleMode(rule.type).options
      resolveData.options = this.syncObject(blankOptions, rule.options)

      this.ruleData = resolveData
      this.$bvModal.show(this.domainRuleId)
    },
  },
  setup() {
    const blankRuleData = {
      key: null,
      name: null,
      required: false,
      type: 'TextType',
      options: {},
    }

    const ruleData = ref(JSON.parse(JSON.stringify(blankRuleData)))

    const resetRuleData = () => {
      ruleData.value = JSON.parse(JSON.stringify(blankRuleData))
    }

    const {
      syncObject,
      ruleTypeOptions,
      resolveRuleMode,
      timeFormatOptions,
    } = useDomainSetting()

    const {
      useAlertToast,
    } = useAlert()

    return {
      ruleData,
      ruleTypeOptions,
      resolveRuleMode,
      blankRuleData,
      resetRuleData,

      syncObject,
      timeFormatOptions,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss">
.vs--open .vs__dropdown-toggle {
  border-radius: 0  !important;
  box-shadow: none !important;
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.model-header {
  padding: 5px 0;
}

.boolean-radios-text {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>
